import React from "react";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { graphql } from "gatsby";
import classes from "../styles/404.module.css";

export default function Error404({ data, location }) {
  return (
    <StylesProvider injectFirst>
      <Layout data={data} location={location}>
        <div className={classes.root}>
          <h2>Page not found</h2>
          <p>The page you are looking for could not be found.</p>
        </div>
      </Layout>
    </StylesProvider>
  );
}

export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
